body{
  /* background: linear-gradient(to right top, #000000, #44244a); */
  background: linear-gradient(to bottom, #1b1b1b, #000000);
  /* background: #150c17; */
  background-color: #000;
  color: #fff;
  max-height: 100vh;
  touch-action: none; /* Prevent touch actions */
  background-repeat: no-repeat;
  margin-top:35px !important;

}

p, em{
  color: #BABABA;
 }

.loadingBg{
  background-image: url('/public/loading.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.homescreen{
  background: linear-gradient(to bottom, #1b1b1b, #000000);
  background-color: #180000;
}

#root{
  height: 100%;
  position: relative;
  width: 100%;
}


.shadowtop{

}

@media only screen and (max-width: 340px) {

  .small-text {
   font-size: 15px;
  }
  .small-text2 {
   font-size: 12px;
  }
  .small-text3 {
   font-size: 9px;
  }


}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroller::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none!important;
  scrollbar-width: none!important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroller {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.claimdiv{
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}
.cards__container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.taskhead{
  background: linear-gradient(to bottom, #1b1b1b, #000000);
}

.taskbg{
  background: linear-gradient(to bottom, #1b1b1b, #000000);
  background-color: #180000;
  color: #fff;
  max-height: 100vh;
  touch-action: none; /* Prevent touch actions */
  background-repeat: no-repeat;
}


.heading{
  text-align: center;
}

.image-container {
  perspective: 1000px;
  display: inline-block;
}

.wobble-image {
  width: 220px;
  transition: transform .2s;
  filter: grayscale(.1);
}
.congratsani{
  transition: visibility 0.5s ease 0s;
}

@keyframes wobble-top {
  0%,
  100% {
    transform: rotateX(0);
  }
  50% {
    transform: rotateX(-20deg);
  }
}

@keyframes wobble-bottom {
  0%,
  100% {
    transform: rotateX(0);
  }
  50% {
    transform: rotateX(20deg);
  }
}

@keyframes wobble-left {
  0%,
  100% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(-20deg);
  }
}

@keyframes wobble-right {
  0%,
  100% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(20deg);
  }
}

.wobble-top {
  animation: wobble-top .2s ease;
}

.wobble-bottom {
  animation: wobble-bottom .2s ease;
}

.wobble-left {
  animation: wobble-left .2s ease;
}

.wobble-right {
  animation: wobble-right .2s ease;
}

.tapguru{
    font-size: 16px;
}
.tapguru{
    font-size: 14px;
}

@media only screen and (max-width: 350px) {

  .tapguru{
    font-size: 12px;
  }
  .tapguru2{
    font-size: 10px;
}


}

.animate-pulse-slow {
  animation: pulse 2s infinite;
}

.spinner {
  width: 160px;
  height: 160px;
  position: fixed;
  border-radius: 1000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}


.spin1{

  width: 110px;
  border: 10px solid #565661;
  height: 110px;
  position: relative;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pulser{
  animation: pulsers 1.5s linear infinite;

}

.loaderan {
  animation-name: pulse-zoomin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
  animation: pulse-zoomin 2s linear 0s infinite normal none;*/
}
@keyframes pulse-zoomin {

  0% {
    transform:scale(1);
    opacity: 1;
  }
  50% {
    transform:scale(0.6);
    opacity: .3;
  }
  100% {
    transform:scale(1);
    opacity: 1;
  }
}

@keyframes pulserss {
  0% {
    opacity: 1;

  }
  50% {
    opacity: .3;

  }
  75% {
    opacity: .6;

  }
  100% {
    opacity: 1;

  }
}

.spin2{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-left-color: #8580cf;

}

.spin3{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-right-color: orange;
  rotate: 70deg;
  z-index: 10;

}

.spin4{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-right-color: orange;
  rotate: 70deg;
  z-index: 10;

}

.spin5{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #383568;
  rotate: 218deg;
  z-index: 20;

}

.spin6{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #8580cf;
  rotate: 275deg;
  z-index: 20;

}

.spin7{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #bcb4a7;
  rotate: 32deg;
  z-index: 30;
}

.spin8{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #373568;
  rotate: -28deg;
  z-index: 30;

}

.spin9{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #373568;
  rotate: 41deg;
  z-index: 25;

}



.tapmore {
  animation-name: shake-center;
  animation-duration: .9s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
  animation: shake-center 1.2s linear 0s infinite normal none;*/
}
@keyframes shake-center {

  0% {
    transform:rotate(0deg);
    transform-origin:50% 50%;
  }
  10% {
    transform:rotate(8deg);
  }
  20% {
    transform:rotate(-10deg);
  }
  30% {
    transform:rotate(10deg);
  }
  40% {
    transform:rotate(-10deg);
  }
  50% {
    transform:rotate(10deg);
  }
  60% {
    transform:rotate(-10deg);
  }
  70% {
    transform:rotate(10deg);
  }
  80% {
    transform:rotate(-8deg);
  }
  90% {
    transform:rotate(8deg);
  }
  100% {
    transform:rotate(0deg);
    transform-origin:50% 50%;
  }
}


@media only screen and (max-width: 340px) {

  .small-text {
   font-size: 15px;
  }
  .small-text2 {
   font-size: 12px;
  }
  .small-text3 {
   font-size: 9px;
  }

  .boostImg{

    width: 30px;
    height: 30px;

  }
  .xxImg{

    width: 28px;
    height: 28px;

  }
  .boostTitle{
    font-size: 12px;
  }
  .boostAmount{
    font-size: 20px;

  }

  .cardios{
    padding: 16px;
  }
  .xxAmount{
    font-size: 24px;
  }
  .xxTitle{
    font-size: 12px;
  }

  .wobble-image{
    width: 160px;
    height: 160px;
  }

  .levelName{
    font-size: 12px;
  }

  .chooseExchange{
    font-size: 10px;
    text-wrap: nowrap;
  }
  .clickContainer{

    padding-bottom: 10px;
    padding-top: 10px;

  }

  .moreTaps{
    font-size: 10px;

  }

  .getBoosters{
    font-size: 13px;
  }

  .barTitle{
    font-size: 12px;
  }

  .levelImg{
    width: 14px;
    height: 14px;
  }

}


/* SpinImage.css */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin-image {
  width: 200px; /* Adjust size as needed */
  cursor: pointer;
  animation: spin linear infinite; /* Infinite spin */
}

.spintap{
  animation: spin 150s linear infinite;
}
@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.glowbutton{
  -webkit-box-shadow:0px 0px 8px 0px rgb(255 255 255 / 86%);
-moz-box-shadow: 0px 0px 8px 0px rgb(255 255 255 / 86%);
box-shadow: 0px 0px 8px 0px rgb(255 255 255 / 86%);
}

.cards{
  /* -webkit-box-shadow:inset 0 0 16px 0 #351e1d; */
/* -moz-box-shadow: inset 0 0 16px 0 #351e1d; */
/* box-shadow: inset 0 0 16px 0 #351e1d; */
/* background: #ffdcdc17; */
background: #ffbfbf29;
/* border: 0.5px solid #644a4a61; */
}


.frosty{
  width: 350px;
  height: 500px;
  background: inherit;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  margin-left: -175px;
  margin-top: -250px;
  border-radius: 8px;
}
.frosty:before{
  width: 400px;
  height: 550px;
  content: "";
  position: absolute;
  top: -25px;
  left: -25px;
  bottom: 0;
  right: 0;
  background: inherit;
  box-shadow: inset 0 0 0 200px rgba(255,255,255,0.2);
  filter: blur(10px);
}

.bordercut{
  border-left: none;
  border-right: none;
}

.spinso{
  animation: spin 50s linear infinite;
}

#okx{
  filter: invert(1);
}

#htx{
  filter: invert(1);
}
#bingx{
  border-radius: 6px;
}


#ton-connect-button button{
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  height: 54px;
}

#ton-connect-button svg{
  height: 28px;
  width: 28px;
}
#ton-connect-button div{
  font-size: 16px;
}

.bg-btn4{
  color: #000;
}

.levelbar{
  background: linear-gradient(to right, #9cdf95, #b6bcb6, #d98edf, #d98edf);
}


/* KEYFRAMES */

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5,.5,.5, 360deg);
  }
  to{
    transform: rotate3d(0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}

/* GRID STYLING */

* {
  box-sizing: border-box;
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* SPINNING CIRCLE */

.leo-border-1 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin3D 1.8s linear 0s infinite;
}

.leo-core-1 {
  width: 100%;
  height: 100%;
  background-color: #37474faa;
  border-radius: 50%;
}

.leo-border-2 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(251, 91, 83);
  background: linear-gradient(0deg, rgba(251, 91, 83, 0.1) 33%, rgba(251, 91, 83, 1) 100%);
  animation: spin3D 2.2s linear 0s infinite;
}

.leo-core-2 {
  width: 100%;
  height: 100%;
  background-color: #1d2630aa;
  border-radius: 50%;
}

/* ALTERNATING ORBITS */

.circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin .8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
  border-radius: 50%;
}

/* X-ROTATING BOXES */

.configure-border-1 {
  width: 115px;
  height: 115px;
  padding: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fb5b53;
  animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
}

.configure-border-2 {
  width: 115px;
  height: 115px;
  padding: 3px;
  left: -115px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(63,249,220);
  transform: rotate(45deg);
  animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
}

.configure-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
}

/* PULSE BUBBLES */

.pulse-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.pulse-bubble-1 {
    animation: pulse .4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
    animation: pulse .4s ease .2s infinite alternate;
}
.pulse-bubble-3 {
    animation: pulse .4s ease .4s infinite alternate;
}

/* SOLAR SYSTEM */

.solar-system {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #fafbfC;
	border-radius: 50%;
}

.earth-orbit {
	width: 165px;
	height: 165px;
  -webkit-animation: spin 12s linear 0s infinite;
}

.venus-orbit {
	width: 120px;
	height: 120px;
  -webkit-animation: spin 7.4s linear 0s infinite;
}

.mercury-orbit {
	width: 90px;
	height: 90px;
  -webkit-animation: spin 3s linear 0s infinite;
}

.planet {
	position: absolute;
	top: -5px;
  width: 10px;
  height: 10px;
	border-radius: 50%;
  background-color: #3ff9dc;
}

.sun {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: #ffab91;
}

.leo {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.blue-orbit {
	width: 165px;
	height: 165px;
  border: 1px solid #91daffa5;
  -webkit-animation: spin3D 3s linear .2s infinite;
}

.green-orbit {
	width: 120px;
	height: 120px;
  border: 1px solid #91ffbfa5;
  -webkit-animation: spin3D 2s linear 0s infinite;
}

.red-orbit {
	width: 90px;
	height: 90px;
  border: 1px solid #ffca91a5;
  -webkit-animation: spin3D 1s linear 0s infinite;
}

.white-orbit {
	width: 60px;
	height: 60px;
  border: 2px solid #ffffff;
  -webkit-animation: spin3D 10s linear 0s infinite;
}

.w1 {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
  transform: rotate3D(1, 2, .5, 90deg);
}

.w3 {
  transform: rotate3D(.5, 1, 2, 90deg);
}

.three-quarter-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #fb5b53;
  border-top: 3px solid transparent;
  animation: spin .5s linear 0s infinite;
}

	.loading-bar {
		display: flex;
		height: 6px;
		width: 100%;
		/* max-width: 320px; */
		/* box-shadow: inset 0px 0px 0px 1px #C8C8CD; */
		/* border-radius: 50px; */
		overflow: hidden;
  }

		@keyframes progress-animation {
			0% {width: 0%;}
			20% {width: 10%;}
			40% {width: 30%;}
			50% {width: 60%;}
			100% {width: 90%;}
		}

		.progress-bar {
			display: flex;
			height: 100%;
			width: 100%;
			background: linear-gradient(to right, #9cdf95, #b6bcb6, #d98edf, #d98edf);
			animation: progress-animation 5s ease-in-out;
		}


#sm {
  background: linear-gradient(to bottom, #5a5a5aa6, #535353);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=0 );

  width: 100%;
  margin: 10px auto 20px;
  padding: 20px;
  border-radius: 20px;
}


#sm, .lever button {box-shadow: 0 3px 9px rgba(0,0,0,.25)}
.group, .reel, .lever {display: inline-block;}
.group, .lever {
background: #a4a4a433;
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fceabb', endColorstr='#fbdf93',GradientType=0 );
}

.group {
border-radius: 20px;
padding: 20px 0 20px 20px;
display: flex;
justify-content: center;
}

.reel {
background: #1f1f1f;
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 );

text-align:center;
float: left;
padding:0 10px;
width: 31%;
height: 100px;
overflow: hidden;
margin-right: 20px;
border-radius: 10px;
box-shadow: 0 2px 7px rgba(0,0,0,.3) inset, 0 0px 1px rgba(0,0,0,.2) inset;
}
.reel div {
  position: relative;
  top: -48px;
}
.reel span {
  font-weight: bold;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
/* .reel p:nth-child(1) {color:#c60;}
.reel p:nth-child(2) {color:#690;}
.reel p:nth-child(3) {color:#630;} */
.reel p:nth-child(1) {padding: 2px; display: flex; justify-content: center; align-items:center; width: 35px; height: 35px; border-radius: 999px;}
.reel p:nth-child(2) {padding: 2px; display: flex; justify-content: center; align-items:center; width: 35px; height: 35px; border-radius: 999px;}
.reel p:nth-child(3) {padding: 2px; display: flex; justify-content: center; align-items:center; width: 35px; height: 35px; border-radius: 999px;}
.reel p:nth-child(4) {padding: 2px; display: flex; justify-content: center; align-items:center; width: 35px; height: 35px; border-radius: 999px;}

/* .reel span:nth-child(1) {color:#c60; background: white; padding: 4px;}
.reel span:nth-child(2) {color:#690; background: black; padding: 4px;}
.reel span:nth-child(3) {color:#630; background: green; padding: 4px;} */

.child0{
background: rgb(81, 99, 97);
color: #fff;
}
.child1{
 color: #000;
}
.child2{
background: red;
color: #fff;
}
.child3{
background: blue;
color: #fff;
}

.lever {
float: right;
padding-right: 20px;
}
.lever button {
text-align:center;
border-radius: 10px;
line-height: 100px;
width: 100px;
border: none;
font-size:1.8em;
-webkit-transition: all .2s ease;
-moz-transition: all .2s ease;
-o-transition: all .2s ease;
background: rgb(252,255,244);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfff4', endColorstr='#e9e9ce',GradientType=0 );
}
.lever button:active {
color: #900;
box-shadow: 0 1px 4px rgba(0,0,0,.3);
margin: 2px 0 -2px;
}

.msg {
text-align: center;
color: #bbb;
font-size:1.8em;
padding: 25px 0 5px;
text-shadow: 0 -1px 0 rgba(0,0,0,.3), 0 1px 0 rgba(255,255,255,.5);
}


.specials1{
  background: linear-gradient(to bottom, #b36bb0, #362338);
}
.specials2{
  background: linear-gradient(to bottom, #b2c0c8, #0d293d);
}
.specials3{
  background: linear-gradient(to bottom, #047265, #01241f);
}
.specials4{
  background: linear-gradient(to bottom, #4c577d, #081530);
}

.pspecials1{
  background: #482d49e2;
}
.pspecials2{
  background: #293e4ce0;
}
.pspecials3{
  background: #02322bde;
}
.pspecials4{
  background: #111e3ae3;
}

.taskbgy{
  background: linear-gradient(to right top, #000000, #411111);
  background-color: #000;
  color: #fff;
  height: var(--tg-viewport-stable-height);
  touch-action: none; /* Prevent touch actions */
}
